.carousel-container {
    margin-bottom: -4%;
}

#hero-split-1 {
    background-image: url(../../../../public/images/split/split.webp);
    background-size: contain;
    background-repeat: no-repeat;
}

/* Carousel */

.carousel {
    text-align: center;
    color: #ffffff;
}

.carousel-slide {
    display: grid;
    justify-items: center;
    max-width: 100%;
    height: 100vh;
}

.carousel-slide-image {
    display: grid;
    align-self: center;
}

.carousel-slide-copy {
    display: grid;
    align-self: center;
    text-align: center;
    padding-top: 25%;
}

.carousel-slide-copy a {
    text-decoration: none;
    color: #ffffff;
    font-size: 1rem;
}

a.button {
    color: #fff;
    background-color: #FFCC00;
    padding: 15px 30px;
    margin-top: 20px;
    text-decoration: none;
}

a.button:hover {
    background-color: #18255A;
}

.button-container {
    padding-top: 50%;
}

.carousel-arrow {
    display: none;
    justify-items: center;
    cursor: pointer;
    position: absolute;
    color: #ffffff;
    width: 17px;
    height: 48px;
}

@media screen and (max-width: 1680px) {

    .carousel-container {
        margin-bottom: -4%;
    }

}

@media screen and (max-width: 1440px) {

    .carousel-container {
        margin-bottom: -4%;
    }

}

@media screen and (max-width: 1280px) {

    .carousel-container {
        margin-bottom: -4%;
    }

}

@media screen and (max-width: 1024px) {

    .carousel-container {
        margin-bottom: -4%;
    }

}

@media screen and (max-width: 990px) {

    .carousel-container {
        margin-bottom: -4%;
    }

    .carousel-slide-copy {
        font-size: 1.5rem;
    }

    .carousel-slide-copy p {
        padding-right: 20px;
    }

    .flickity-page-dots {
        display: none;
    }
}



@media screen and (max-width: 620px) {

    .carousel-container {
        margin-bottom: -4%;
    }

    .carousel-slide {
        margin-bottom: -4%;
        height: vh;
        grid-template-columns: 1fr;
    }

    .carousel-slide-image {
        justify-self: center;
        align-content: center;
    }

    .carousel-slide-copy {
        justify-self: center;
        text-align: center;
    }
}

@media screen and (max-width: 568px) {

    .carousel-container {
        margin-bottom: -4%;
    }

    .carousel-slide {
        grid-template-columns: 1fr;
        height: 35vh;
        width: auto;
    }

    .carousel-slide-image {
        justify-self: center;
    }

    .carousel-slide-copy {
        display: grid;
        align-self: center;
        text-align: center;
        padding-top: 24%;
        padding-left: 34%;
    }

    .carousel-slide-image {
        justify-self: center;
        align-self: end;
    }

    .carousel-slide-copy {
        font-size: 1.5rem;
        justify-self: start;
        align-self: start;
        text-align: center;
    }

}

@media screen and (max-width: 480px) {

    .carousel-container {
        margin-bottom: -4%;
    }

    .carousel-slide {
        grid-template-columns: 1fr;
        height: 800px;
        margin-bottom: -4%;
    }

    .carousel-slide-image {
        justify-self: center;
        height: 200px;
    }

    .carousel-cell img {
        height: 200px;
    }

}

/* iPhone 8 Plus, 7 Plus, 6 Plus */
@media screen and (max-width: 414px) {

    /* Home Page */

    .carousel-container {
        margin-bottom: -4%;
    }

    .carousel-slide {
        grid-template-columns: 1fr;
        height: 25vh;
        width: auto;
    }

    .carousel-slide-image {
        justify-self: center;
    }

    .carousel-slide-copy {
        display: grid;
        align-self: center;
        text-align: center;
        padding-top: 20%;
        padding-left: 30%;
    }

    .carousel-slide-image {
        justify-self: center;
        align-self: end;
    }

    .carousel-slide-copy {
        font-size: 1.5rem;
        justify-self: start;
        align-self: start;
        text-align: center;
    }

}

/* iPhone X, 8, 7, 6 */
@media screen and (max-width: 375px) {

    .carousel-container {
        margin-bottom: -3%;
    }

    .carousel-slide {
        grid-template-columns: 1fr;
        height: 25vh;
        width: 100vw;
    }

    .carousel-slide-image {
        justify-self: center;
    }

    .carousel-slide-copy {
        display: grid;
        align-self: center;
        text-align: center;
        padding-top: 20%;
        padding-left: 37%;
    }

    .carousel-slide-copy a {
        text-decoration: none;
        color: #ffffff;
        font-size: 1rem;
    }

    a.button {
        color: #fff;
        background-color: #FFCC00;
        padding: 15px 30px;
        text-decoration: none;
        font-size: 5px;
    }

    a.button:hover {
        background-color: #18255A;
    }

    .button-container {
        font-size: small;
    }


    .carousel-slide-image {
        justify-self: center;
        align-self: end;
    }
}