.cards1 {
    padding-left: 10px;
    padding-right: 10px;
    color: #ffffff;
    background-color: #fff;
    background-position: center;
}

.comercios {
    padding-top: 120px;
    color: #000;
    font-family: 'Gill Sans', Calibri;
}

.svg-container {
    width: 90%;
    height: 90%;
}

.svg {
    width: 100%;
    height: 100%;
}

#mapa path {
    fill: #18255A;
    stroke: #ffffff;
    stroke-width: 2px;
}

#mapa :hover {
    fill: #FFCC00;
}

.row {
    margin-left: -10px !important;
    margin-right: -10px !important;
}