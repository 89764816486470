.comerce {
    background-color: #D0D0CF !important;
}

h2 {
    font-weight: bold !important;
    color: #000;
}

.accordion {
    --bs-accordion-active-bg: #18255A !important;
    --bs-accordion-btn-focus-border-color: #FFCC00 !important;
    --bs-accordion-active-color: #FFCC00 !important;
    --bs-accordion-btn-focus-border-color: #18255A !important;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
    --bs-accordion-btn-focus-box-shadow: #FFCC00 !important;
}

.acordion-header {
    align-items: center !important;
    font-weight: bold !important;
}

.accordion-button {
    font-weight: 600;
}

.logotipos {
    width: auto !important;
    height: 70px !important;
}


.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: black;
    background-size: 100%, 100%;
    background-image: none;
}

.carousel-control-next-icon:after {
    content: '>';
    font-size: 35px;
    color: black;
}

.carousel-control-prev-icon:after {
    content: '<';
    font-size: 35px;
    color: black;
}

.carousel-indicators [data-bs-target] {
    display: none;
}



.hero-container {
    height: 80% !important;
    background-color: #212529;
    font-family: 'Bebas Neue', sans-serif;
}

.image-title {
    width: 30%;
    height: 30%;
}

.ubic {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.ubic iframe,
.ubic object,
.ubic embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.contacts {
    color: #000000 !important;
}

.contacts:hover {
    color: #18255A !important;
}

.contacts:active {
    color: #18255A !important;
}